import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_@playwright+test@1.47.1_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"Space_Grotesk\",\"arguments\":[{\"subsets\":[\"latin\",\"latin-ext\"],\"display\":\"swap\",\"preload\":true,\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\"],\"variable\":\"--font-sans\",\"fallback\":[\"system-ui\",\"Inter\"]}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Menu"] */ "/app/src/app/components/menu/menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/app/ui/toast/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/app/src/trpc/react.tsx");
